// Función para mostrar y ocultar el menú toggle
const showMenu = (toggleId, navId) => {
    // Obtiene los elementos del DOM por su ID
    const toggle = document.getElementById(toggleId),
        nav = document.getElementById(navId);

    // Añade un evento de clic al toggle
    toggle.addEventListener('click', () => {
        // Alterna la clase show-menu en el elemento nav
        nav.classList.toggle('show-menu');

        // Alterna la clase show-icon en el elemento toggle
        toggle.classList.toggle('show-icon');
    });

    // Selecciona todos los enlaces dentro del menú
    const navLinks = document.querySelectorAll('.nav__link');
    navLinks.forEach(link => {
        // Añade un evento de clic a cada enlace
        link.addEventListener('click', (e) => {
            // Verifica si el enlace tiene un atributo href que apunta a una sección de la página
            const href = link.getAttribute('href');
            if (href && href.startsWith('#')) {
                // Remueve la clase show-menu del elemento nav
                nav.classList.remove('show-menu');
                // Remueve la clase show-icon del elemento toggle
                toggle.classList.remove('show-icon');

                // Previene el comportamiento predeterminado del enlace
                e.preventDefault();

                // Desplazamiento suave al elemento objetivo
                const targetElement = document.querySelector(href);
                targetElement.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        });
    });
};

// Llama a la función showMenu con los IDs del toggle y del menú
showMenu('nav-toggle', 'nav-menu');

// DropDown Menú
const dropdownItems = document.querySelectorAll('.dropdown__item');

// 1. Seleccionar cada item de dropdown
dropdownItems.forEach((item) => {
    // Obtiene el botón del dropdown dentro del item
    const dropdownButton = item.querySelector('.dropdown__button');

    // 2. Añade un evento de clic al botón del dropdown
    dropdownButton.addEventListener('click', () => {
        // Selecciona el item que ya está mostrando el dropdown
        const showDropdown = document.querySelector('.show-dropdown');
        // Alterna el estado del dropdown para el item actual
        toggleItem(item);
        // Si hay otro dropdown abierto, lo cierra
        if (showDropdown && showDropdown != item) {
            toggleItem(showDropdown);
        }
    });
});

// 3. Crear una función para mostrar el dropdown
const toggleItem = (item) => {
    // Obtiene el contenedor del dropdown dentro del item
    const dropdownContainer = item.querySelector('.dropdown__container');

    // Si el item ya tiene la clase show-dropdown
    if (item.classList.contains('show-dropdown')) {
        // Remueve el estilo del contenedor del dropdown
        dropdownContainer.removeAttribute('style');
        // Remueve la clase show-dropdown del item
        item.classList.remove('show-dropdown');
    } else {
        // Establece la altura del contenedor del dropdown
        dropdownContainer.style.height = dropdownContainer.scrollHeight + 'px';
        // Añade la clase show-dropdown al item
        item.classList.add('show-dropdown');
    }
};

// Media Query para pantallas de al menos 1128px de ancho
const mediaQuery = matchMedia('(min-width: 1128px)'),
    dropdownContainer = document.querySelectorAll('.dropdown__container');

// Función para remover estilos en pantallas grandes
const removeStyle = () => {
    if (mediaQuery.matches) {
        // Remueve los estilos de cada contenedor del dropdown
        dropdownContainer.forEach((e) => {
            e.removeAttribute('style');
        });

        // Remueve la clase show-dropdown de cada item
        dropdownItems.forEach((e) => {
            e.classList.remove('show-dropdown');
        });
    }
};

// Añade un evento de redimensionamiento de la ventana
addEventListener('resize', removeStyle);

// Código adicional para manejar el desplazamiento suave con scrollIntoView
document.addEventListener("DOMContentLoaded", function() {
    const links = document.querySelectorAll('.scroll-link');

    for (const link of links) {
        link.addEventListener("click", function(e) {
            e.preventDefault();

            const href = this.getAttribute("href");
            const targetElement = document.querySelector(href);

            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: "smooth"
                });
            }
        });
    }
});

lightbox.option({
    'resizeDuration': 200,
    'wrapAround': true,
    disableScrolling: true,
})